// 
// dropdowns.scss
// Extended from Bootstrap
// 

// 
// Bootstrap Overrides
//
.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

// dropdown item active
.dropdown-item.active {
  color: $primary;
}

// Dropdown arrows
// Replace original arrows with Custom icons
// 
.dropdown-toggle {
  &:after {
    content: "\f078";
    font-family: 'Font Awesome 5 Free';
    font-size: 0.5rem;
    margin-left: 0.35rem;
    font-weight: 900;
    vertical-align: middle;
    border: none;
  }
}

.dropup .dropdown-toggle {
  &:after {
    content: "\f077";
    font-family: 'Font Awesome 5 Free';
    font-size: 0.7rem;
    margin-left: 0.35rem;
    font-weight: 900;
    vertical-align: middle;
    border: none;
  }
}

// Dropdown overrides for navbar only
.dropdown-menu .dropdown-toggle {
  &:after {
    position: absolute;
    right: 25px;
    top: 10px
  }
  &:before {
    position: absolute;
    right: 25px;
    top: 10px
  }
}

// 
// Additional style for theme
// 

// dropdown menu md size
.dropdown-menu-size-md{
  min-width: 22rem;
}

// dropdown breakpoint
@include media-breakpoint-down(sm) {
  .dropdown-menu-size-md{
    min-width: 16rem;
  }
}